import $ from 'cash-dom';
export default $(function () {
    var _a;
    if ($('.faq-categories').length) {
        $(window).one('hashchange', () => {
            var _a;
            if (!window.location.hash) {
                window.location.hash = (_a = $('.faq-categories--selector--item')
                    .first()) === null || _a === void 0 ? void 0 : _a.attr('href');
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
        });
        if (!window.location.hash) {
            window.location.hash = (_a = $('.faq-categories--selector--item')
                .first()) === null || _a === void 0 ? void 0 : _a.attr('href');
        }
    }
    $('.faq-categories--selector--item').on('click', function (event) {
        if (window.innerWidth > 880) {
            return;
        }
        const target = event.currentTarget;
        if ($(target).parent().hasClass('selector--open')) {
            $(target).parent().removeClass('selector--open');
        }
        else {
            $(target).parent().addClass('selector--open');
            event.preventDefault();
        }
    });
});
