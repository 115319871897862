const defaultConfig = {
    lazy: true,
    fireevent: 'click',
    autoplay: false,
    autoscale: true,
    showscroll: false,
    type: undefined,
    attributes: undefined
};
export default defaultConfig;
