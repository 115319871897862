import addStyles from "../../styles/functions/addStyles";
class LightBoxModal {
    constructor(container) {
        this.lastTabPos = 0;
        this.modal = null;
        this.container = container;
    }
    getModal() {
        var _a;
        if (this.modal) {
            return this.modal;
        }
        this.modal = this.buildModal();
        (_a = this.modal) === null || _a === void 0 ? void 0 : _a.addEventListener('mousedown', e => {
            const path = e.composedPath();
            if (!path.find((el, i) => { var _a; return i == 1 && ((_a = el.classList) === null || _a === void 0 ? void 0 : _a.contains('lightbox-container__media')); }))
                this.container.destroyContainer();
        });
        return this.modal;
    }
    buildModal() {
        const modal = document.createElement('div');
        modal.id = 'dragon-lightbox-modal';
        modal.classList.add('lightbox-modal');
        modal.prepend(addStyles(this.container.config));
        if (this.container.elements.length == 1)
            return modal;
        modal.addEventListener('touchstart', ({ touches }) => {
            this.lastTabPos = touches[0].screenX;
            this.container.mediaElement.style.transition = 'none';
        });
        modal.addEventListener('touchmove', ({ touches }) => this.container.mediaElement.style.transform = `translateX(${touches[0].screenX - this.lastTabPos}px)`);
        modal.addEventListener('touchend', ({ changedTouches }) => {
            this.container.mediaElement.style.transition = '';
            const endTabPos = changedTouches[0].screenX;
            this.container.mediaElement.style.transform = ``;
            if (Math.abs(endTabPos - this.lastTabPos) < 100)
                return;
            if (endTabPos > this.lastTabPos)
                this.container.prev();
            else
                this.container.next();
        });
        return modal;
    }
}
export default LightBoxModal;
