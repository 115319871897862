import ContainerAttributes from "../../constants/containerAttributes";
const getConfigByAttributes = (config, attributes) => {
    for (const { name, value } of attributes) {
        const parsedName = name.replace(`${ContainerAttributes.DATA}-`, '');
        if (Object.keys(config).includes(parsedName)) {
            let parsedValue = value;
            parsedValue = !value || value.toLowerCase() === 'true' ? true : value.toLowerCase() === 'false' ? false : parsedValue;
            //number check
            parsedValue = /^[0-9]+$/.test(parsedValue) ? Number(parsedValue) : parsedValue;
            config = Object.assign(Object.assign({}, config), { [parsedName]: parsedValue });
        }
    }
    return config;
};
export default getConfigByAttributes;
