var ContainerAttributes;
(function (ContainerAttributes) {
    ContainerAttributes["CONTAINER"] = "data-dlightbox-container";
    ContainerAttributes["INITIALIZER"] = "data-dlightbox";
    ContainerAttributes["IGNORE"] = "data-ignore";
    ContainerAttributes["ID"] = "data-id";
    ContainerAttributes["TYPE"] = "data-type";
    ContainerAttributes["DATA"] = "data";
    ContainerAttributes["CACHED"] = "data-cached"; //used internally
})(ContainerAttributes || (ContainerAttributes = {}));
export default ContainerAttributes;
