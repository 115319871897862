import { autoscale_image } from '../../styles/functions/getAutoscale';
import DragonLightBox from '../abstract/DragonLightBox';
class ImageLightBox extends DragonLightBox {
    constructor(resource, attributes, config) {
        super(resource, attributes, config);
    }
    buildElement() {
        var _a;
        const imageContainer = document.createElement('div');
        const image = document.createElement('img');
        image.hidden = true;
        imageContainer.append(image);
        this.element = imageContainer;
        this.setCommonAttributes();
        image.setAttribute('style', autoscale_image(this.config.autoscale));
        imageContainer.classList.add('dlightbox-image');
        const title = document.createElement('h4');
        title.textContent = this.element.title;
        const caption = document.createElement('p');
        caption.textContent =
            (_a = this.element.attributes.getNamedItem('caption')) === null || _a === void 0 ? void 0 : _a.nodeValue;
        const footerContent = document.createElement('div');
        const footer = document.createElement('div');
        footer.classList.add('dlightbox-image__footer');
        footerContent.append(title);
        footerContent.append(caption);
        footer.append(footerContent);
        imageContainer.append(footer);
        this.spinner.showSpinner();
        image.src = this.resourceUrl;
        image.onerror = () => {
            this.loaded = false;
            if (image.getAttribute('src') == '')
                return;
            this.spinner.showSpinner('Error on loading image');
            this.spinner.element.classList.add('error');
        };
        image.onload = () => {
            this.spinner.hideSpinner();
            this.loaded = true;
            // imageContainer.classList.add('lightbox-shadow')
            image.hidden = false;
        };
    }
}
export default ImageLightBox;
