import Glide from '@glidejs/glide';
import $ from 'cash-dom';
export default $(function () {
    const glideElement = $('.slider').get(0);
    if (!glideElement) {
        return;
    }
    const slider = new Glide('.slider', {
        type: 'carousel',
        startAt: 0,
        perView: 3,
        bound: true,
        autoplay: 3000,
        gap: 30,
        animationDuration: 600,
        rewindDuration: 600,
        animationTimingFunc: 'ease-in-out',
        breakpoints: {
            800: {
                perView: 2,
            },
        },
    }).mount();
    $('.glide--arrow--left').on('click', () => {
        slider.go('<');
    });
    $('.glide--arrow--right').on('click', () => {
        slider.go('>');
    });
});
