import mobile_breakpoint from '../constants/mobile_breakpoint';
const lightbox_types_styles = (config) => `
.dlightbox-image {


}
.dlightbox-image__footer {
    display: flex;
    font-size: 16px;
    color: white;
    padding: 15px 0 0;
}
.dlightbox-image__footer h4 {
    color: white;
    font-size: 24px;
    margin-bottom: 5px;
}
.dlightbox-image img {
    max-height: 70vh;
}
.dlightbox-video {
    max-height: inherit;
    max-width: unset;
    object-fit: contain;
}
.dlightbox-embed {
    max-height: inherit;
    max-width: unset;
}
@media (max-width: ${mobile_breakpoint}px) {
    .dlightbox-video, .dlightbox-embed { width: 100% !important }
    .dlightbox-embed { height: calc(100vw - 20vw) !important }
    .dlightbox-image__footer { padding: 15px }    
}
`;
export default lightbox_types_styles;
