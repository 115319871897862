import $ from 'cash-dom';
import { animate } from 'popmotion';
const faqOpenClass = 'faq--open';
export default $(function () {
    $('.faq--question').on('click', function (event) {
        const target = event.currentTarget;
        const parent = $(target).parent();
        const answer = $(target).siblings('.faq--answer');
        if (answer.height() !== 0) {
            parent.removeClass(faqOpenClass);
            animate({
                from: answer.innerHeight(),
                to: 0,
                onUpdate: (height) => {
                    answer.height(height);
                },
                onComplete: () => {
                    answer.hide();
                },
            });
        }
        else {
            answer.show();
            answer.css('height', 'auto');
            const newHeight = answer.innerHeight();
            parent.addClass(faqOpenClass);
            animate({
                from: 0,
                to: newHeight,
                onUpdate: (height) => {
                    answer.height(height);
                },
                onComplete: () => {
                    answer.css('height', 'auto');
                },
            });
        }
    });
});
