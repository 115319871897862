import $ from 'cash-dom';
import DLightBox from '../../packages/dragon-lightbox/src/classes/DLightBox';
export default $(function () {
    new DLightBox();
    const videoResources = [];
    const videoElements = [];
    const videoLinks = $('a').filter((_, el) => { var _a; return (_a = el.getAttribute('href')) === null || _a === void 0 ? void 0 : _a.includes('youtube.com/'); });
    if (!videoLinks.length) {
        return;
    }
    videoLinks.each((_, el) => {
        videoResources.push([el.getAttribute('href')]);
        videoElements.push(el);
    });
    new DLightBox().create(videoResources).bind({ elements: videoElements });
});
