import $ from 'cash-dom';
import { animate } from 'popmotion';
export default $(function () {
    /**
     * Open Gallery when anchor link is used
     */
    if (window.location.hash.includes('gallery')) {
        const gallery = $('.gallery-hidden');
        const parent = $(gallery).parent();
        const target = $(parent)
            .find('.before-and-after--view-all')
            .get()[0];
        if (!parent || !target) {
            return;
        }
        expandGallery(gallery, target);
    }
    /**
     * Toggle Gallery when show button is pressed
     */
    $('.before-and-after--view-all').on('click', (e) => {
        const target = e.currentTarget;
        e.preventDefault();
        const parent = $(target).parent();
        const gallery = $(parent).siblings('.gallery-hidden');
        if (gallery.height() !== 0) {
            compressGallery(gallery, target);
        }
        else {
            expandGallery(gallery, target);
        }
    });
});
function compressGallery(gallery, target) {
    $(target).removeClass('active');
    animate({
        from: gallery.innerHeight(),
        to: 0,
        duration: 600,
        onUpdate: (height) => {
            gallery.height(height);
        },
        onComplete: () => {
            gallery.hide();
        },
    });
}
function expandGallery(gallery, target) {
    gallery.show();
    gallery.css('height', 'auto');
    const newHeight = gallery.innerHeight();
    $(target).addClass('active');
    animate({
        from: 0,
        to: newHeight,
        duration: 600,
        onUpdate: (height) => {
            gallery.height(height);
        },
        onComplete: () => {
            gallery.css('height', 'auto');
        },
    });
}
