import ContainerAttributes from '../../constants/containerAttributes';
import customEvents from '../../constants/customEvents';
import DragonLightBox from '../abstract/DragonLightBox';
import DLightBox from '../DLightBox';
import LightBoxList from '../lightbox-list/LightBoxList';
import LightBoxModal from '../lightbox-modal/LightBoxModal';
import LightBoxSpinner from '../lightbox-spinner/LightBoxSpinner';
class LightBoxContainer {
    constructor(resources, config, nobind) {
        this._bindedElements = [];
        this.nodeListener = (event) => {
            var _a, _b, _c;
            if (event.type == 'keydown') {
                if (event.key != 'Enter')
                    return;
            }
            const id = (_b = (_a = event.composedPath()
                .find((_el) => this._bindedElements
                .map((eb) => eb.elements)
                .flat(1)
                .find((el) => el === _el))) === null || _a === void 0 ? void 0 : _a.getAttribute(ContainerAttributes.ID)) !== null && _b !== void 0 ? _b : '0';
            const lightbox = this.getLightBoxById(Number(id));
            if (event.target.nodeType === 1)
                event.preventDefault();
            this.openContainer(lightbox);
            (_c = document.getElementById('lightbox-container__hidden-tabindex')) === null || _c === void 0 ? void 0 : _c.focus();
        };
        this.closeContainerBodyEvent = (event) => {
            if (this.container && event.key === 'Escape') {
                this.destroyContainer();
            }
        };
        this.nextArrowModalEvent = (event) => {
            if (this.container && event.key === 'ArrowRight') {
                this.next(event);
            }
        };
        this.prevArrowModalEvent = (event) => {
            if (this.container && event.key === 'ArrowLeft') {
                this.prev(event);
            }
        };
        this.buildCustomEvents = (props) => {
            const eventMap = new Map();
            for (const name of Object.values(customEvents)) {
                eventMap.set(name, new CustomEvent(name, props));
            }
            return eventMap;
        };
        this.container = null;
        this.id = DLightBox._instances.size;
        this.mediaElement = document.createElement('div');
        this._config = config;
        this.modal = new LightBoxModal(this);
        this.spinner = LightBoxSpinner.getSpinner();
        this.elementCount = resources.length;
        this.lightboxList = new LightBoxList(resources, this.config);
        this.selectedBox = this.lightboxList.head;
        this.selectedBox.lightbox.isSelected = true;
        this._elements = this.lightboxList.elements;
        this.events = this.buildCustomEvents({
            detail: {
                config: this.config,
                count: this.elementCount,
                id: this.id,
                elements: this.elements,
                selectedBox: () => ({
                    resourceUrl: this.selectedBox.lightbox.resourceUrl,
                    element: this.selectedBox.element,
                    config: this.selectedBox.lightbox.config,
                    attributes: this.selectedBox.lightbox.attributes,
                }),
            },
        });
        if (!nobind) {
            const elementsBind = {
                elements: this.elements,
                fireevent: config.fireevent,
            };
            this.addNodeEventListeners(elementsBind);
        }
    }
    get config() {
        return this._config;
    }
    get elements() {
        return this._elements;
    }
    get bindings() {
        return this._bindedElements;
    }
    listen(listener, cb) {
        this.mediaElement.addEventListener(listener, cb);
    }
    addNodeEventListeners(elementsList) {
        if (!('length' in elementsList)) {
            if (!elementsList.hasOwnProperty('elements')) {
                if ([elementsList].length !== this.elementCount) {
                    throw new Error(`You must provide a set of ${this.elementCount} elements!`);
                }
                this.bindElements([elementsList]);
                this._bindedElements.push({
                    elements: [elementsList],
                    fireevent: this.config.fireevent,
                });
                return;
            }
        }
        else {
            //check if NodeList o ElementBind
            if (!elementsList.hasOwnProperty('elements')) {
                if (elementsList.length !== this.elementCount) {
                    throw new Error(`You must provide a set of ${this.elementCount} elements!`);
                }
                this.bindElements([...Array.from(elementsList)].map((e) => e));
                this._bindedElements.push({
                    elements: [...Array.from(elementsList)].map((e) => e),
                    fireevent: this.config.fireevent,
                });
                return;
            }
        }
        const { elements, fireevent } = elementsList;
        this.bindElements(elements, fireevent);
        this._bindedElements.push({ elements, fireevent });
    }
    bindElements(elementsList, fireevent) {
        this.lightboxList.setElementsId(elementsList).forEach((element) => {
            element.addEventListener(fireevent !== null && fireevent !== void 0 ? fireevent : this.config.fireevent, this.nodeListener);
            if (fireevent == 'click')
                element.addEventListener('keydown', this.nodeListener);
        });
    }
    removeNodeEventListeners() {
        this.destroyContainer();
        for (const { elements, fireevent } of this._bindedElements) {
            elements.forEach((element) => {
                element.removeEventListener(fireevent !== null && fireevent !== void 0 ? fireevent : this.config.fireevent, this.nodeListener);
                if (fireevent == 'click')
                    element.removeEventListener('keydown', this.nodeListener);
            });
        }
    }
    createContainer() {
        this.container = document.createElement('div');
        this.config.showscroll
            ? null
            : document.body.classList.add('dlightbox-hidden');
        document.body.addEventListener('keydown', this.closeContainerBodyEvent);
        this.container.classList.add('lightbox-container');
        this.container.setAttribute('tabindex', '-1');
        this.mediaElement.setAttribute('tabindex', '0');
        this.mediaElement.classList.add('lightbox-container__media');
        this.mediaElement.append(this.spinner.element);
        const close = document.createElement('div');
        close.classList.add('lightbox-container__close');
        close.setAttribute('tabindex', '0');
        close.addEventListener('keydown', ({ key }) => {
            if (key === 'Enter')
                this.destroyContainer();
        });
        this.container.prepend(close);
        if (this.elementCount > 1) {
            const nextArrow = document.createElement('div');
            const prevArrow = document.createElement('div');
            nextArrow.classList.add('lightbox-container__next-arrow');
            prevArrow.classList.add('lightbox-container__prev-arrow');
            nextArrow.setAttribute('tabindex', '0');
            prevArrow.setAttribute('tabindex', '0');
            this.container.addEventListener('keydown', ({ key }) => {
                if (key === 'Tab' && document.activeElement === nextArrow) {
                    setTimeout(() => prevArrow.focus(), 0);
                }
            });
            nextArrow.addEventListener('mousedown', (event) => this.next(event));
            prevArrow.addEventListener('mousedown', (event) => this.prev(event));
            nextArrow.addEventListener('keydown', (event) => {
                if (event.key === 'Enter')
                    this.next(event);
            });
            prevArrow.addEventListener('keydown', (event) => {
                if (event.key === 'Enter')
                    this.prev(event);
            });
            this.modal
                .getModal()
                .addEventListener('keydown', this.nextArrowModalEvent);
            this.modal
                .getModal()
                .addEventListener('keydown', this.prevArrowModalEvent);
            this.container.prepend(prevArrow);
            this.container.append(this.mediaElement);
            this.container.append(nextArrow);
        }
        else {
            const lastFocusableElement = document.createElement('div');
            lastFocusableElement.tabIndex = 0;
            this.container.append(lastFocusableElement);
            close.addEventListener('keydown', ({ key }) => {
                if (key === 'Tab') {
                    setTimeout(() => this.mediaElement.focus(), 0);
                }
            });
            this.container.prepend(this.mediaElement);
        }
        setTimeout(() => this.container.focus(), 0);
    }
    destroyContainer() {
        if (!this.container)
            return;
        this.modal.getModal().classList.remove('lightbox-modal-open');
        this.mediaElement.classList.remove('lightbox-container__media-open');
        setTimeout(() => {
            var _a;
            this.mediaElement.blur();
            document.body.removeAttribute('tabindex');
            this.selectedBox.lightbox.close();
            this.container.remove();
            this.modal.getModal().remove();
            document.body.classList.remove('dlightbox-hidden');
            document.body.removeEventListener('keydown', this.closeContainerBodyEvent);
            this.modal
                .getModal()
                .removeEventListener('keydown', this.nextArrowModalEvent);
            this.modal
                .getModal()
                .removeEventListener('keydown', this.prevArrowModalEvent);
            (_a = this.mediaElement) === null || _a === void 0 ? void 0 : _a.dispatchEvent(this.events.get(customEvents.CLOSE));
            this.container = null;
        }, 200);
    }
    getLightBoxById(index) {
        return this.lightboxList.lookupById(index);
    }
    openContainer(box = this.lightboxList.head) {
        var _a;
        if (document.getElementById('dragon-lightbox-modal'))
            return;
        document.body.setAttribute('tabindex', '-1');
        this.spinner.element.classList.remove('error');
        const lightboxNode = Number.isInteger(box)
            ? this.getLightBoxById(box)
            : box;
        if (!(lightboxNode.lightbox instanceof DragonLightBox)) {
            throw new Error('Invalid lightbox');
        }
        const modal = this.modal.getModal();
        this.createContainer();
        setTimeout(() => {
            this.modal.getModal().classList.add('lightbox-modal-open');
            this.mediaElement.classList.add('lightbox-container__media-open');
        }, 0);
        lightboxNode.lightbox.open();
        this.mediaElement.append(lightboxNode.lightbox.element);
        modal.append(this.container);
        modal.append(this.container);
        document.body.prepend(modal);
        this.selectedBox.lightbox.isSelected = false;
        this.selectedBox = lightboxNode;
        this.selectedBox.lightbox.isSelected = true;
        (_a = this.mediaElement) === null || _a === void 0 ? void 0 : _a.dispatchEvent(this.events.get(customEvents.OPEN));
    }
    appendMediaElement(element) {
        if (![...Array.from(this.mediaElement.children)].some((el) => el === element)) {
            this.mediaElement.append(element);
        }
    }
    openLightBox(lightboxnode, isNext) {
        const selectedBox = lightboxnode;
        setTimeout(() => {
            this.mediaElement.classList.remove(`lightbox-type__closed-${isNext ? 'left' : 'right'}`);
        }, 100);
        this.mediaElement.classList.add(`lightbox-type__closed-${isNext ? 'left' : 'right'}`);
        selectedBox.lightbox.open();
        this.appendMediaElement(lightboxnode.lightbox.element);
        this.selectedBox = isNext ? selectedBox.prev : selectedBox.next;
    }
    closeLightBox(node) {
        node.lightbox.close();
    }
    next(event) {
        var _a;
        event === null || event === void 0 ? void 0 : event.stopPropagation();
        event === null || event === void 0 ? void 0 : event.preventDefault();
        this.closeLightBox(this.selectedBox);
        if (this.selectedBox === this.lightboxList.tail) {
            this.openLightBox(this.lightboxList.head, true);
            this.selectedBox = this.lightboxList.head;
            this.selectedBox.lightbox.isSelected = true;
        }
        else {
            this.openLightBox(this.selectedBox.next, true);
            this.selectedBox = this.selectedBox.next;
            this.selectedBox.lightbox.isSelected = true;
        }
        (_a = this.mediaElement) === null || _a === void 0 ? void 0 : _a.dispatchEvent(this.events.get(customEvents.CHANGE));
    }
    prev(event) {
        var _a;
        event === null || event === void 0 ? void 0 : event.stopPropagation();
        event === null || event === void 0 ? void 0 : event.preventDefault();
        this.closeLightBox(this.selectedBox);
        if (this.selectedBox === this.lightboxList.head) {
            this.openLightBox(this.lightboxList.tail, false);
            this.selectedBox = this.lightboxList.tail;
            this.selectedBox.lightbox.isSelected = true;
        }
        else {
            this.openLightBox(this.selectedBox.prev, false);
            this.selectedBox = this.selectedBox.prev;
            this.selectedBox.lightbox.isSelected = true;
        }
        (_a = this.mediaElement) === null || _a === void 0 ? void 0 : _a.dispatchEvent(this.events.get(customEvents.CHANGE));
    }
}
export default LightBoxContainer;
