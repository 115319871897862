import ContainerAttributes from '../constants/containerAttributes';
import defaultConfig from '../constants/defaultConfig';
import smartAttributes from '../constants/smartAttributes';
import getConfigByAttributes from './functions/getConfigByAttributes';
import LightBoxContainer from './lightbox-container/LightBoxContainer';
class DLightBox {
    constructor() {
        this.getConfig = (container, _attributes) => {
            const attributes = _attributes
                ? _attributes
                : Object.values(container.attributes).map((attr) => ({
                    name: attr.name,
                    value: attr.nodeValue,
                }));
            return getConfigByAttributes(defaultConfig, attributes);
        };
        this.getAttributes = (element) => {
            const _attributes = [];
            if (!element.attributes)
                return _attributes;
            const omittedAttr = ['tabindex', 'class'];
            for (const { name, nodeValue } of Object.values(element.attributes)) {
                if (omittedAttr.includes(name.toLowerCase()))
                    continue;
                const parsedName = name.replace(`${ContainerAttributes.DATA}-`, '');
                _attributes.push({ name: parsedName, value: nodeValue !== null && nodeValue !== void 0 ? nodeValue : '' });
            }
            return _attributes;
        };
        DLightBox._instances = new Map();
        this.autoinit();
    }
    get instances() {
        return DLightBox._instances;
    }
    create(resources, _config) {
        var _a, _b, _c;
        try {
            const resourcesList = Array.isArray(resources[0])
                ? resources
                : [[resources]];
            const resourceElements = new Array();
            for (const [url, attributes] of resourcesList) {
                const element = document.createElement('div');
                const _attributes = (_a = attributes) !== null && _a !== void 0 ? _a : null;
                if (_attributes &&
                    _attributes.find((a) => a.name === ContainerAttributes.TYPE)) {
                    element.setAttribute(ContainerAttributes.TYPE, (_c = (_b = _attributes.find((a) => a.name === ContainerAttributes.TYPE)) === null || _b === void 0 ? void 0 : _b.value) !== null && _c !== void 0 ? _c : '');
                }
                element.setAttribute('data-dlightbox', String(url));
                resourceElements.push({ element, attributes: _attributes });
            }
            const config = _config
                ? Object.assign(Object.assign({}, defaultConfig), _config) : defaultConfig;
            const lb = new LightBoxContainer(resourceElements, config, true);
            return DLightBox.createInstanceObject(lb);
        }
        catch (error) {
            throw new Error(`Invalid dlightbox input: ${error.message}`);
        }
    }
    autoinit() {
        console.log('Initialising');
        const groupContainers = document.querySelectorAll(`[${ContainerAttributes.CONTAINER}]`);
        const soloContainers = document.querySelectorAll(`[${ContainerAttributes.INITIALIZER}]:not([${ContainerAttributes.CONTAINER}] [${ContainerAttributes.INITIALIZER}])`);
        const initContainer = (container, resources) => {
            let config = this.getConfig(container);
            config.attributes = this.getAttributes(container);
            config = Object.assign(Object.assign({}, config), this.getConfig(container, config.attributes));
            const lb = new LightBoxContainer(resources, config);
            DLightBox.createInstanceObject(lb);
        };
        for (const container of Array.from(groupContainers)) {
            const _smartAttributes = smartAttributes.map((attr) => `[${attr}]`);
            const resources = Array.from(container.querySelectorAll(`${_smartAttributes.join()}`))
                .filter((element) => !element.hasAttribute(ContainerAttributes.IGNORE))
                .map((element) => ({
                element,
                attributes: this.getAttributes(element),
            }));
            if (resources.length)
                initContainer(container, resources);
        }
        for (const container of Array.from(soloContainers)) {
            const resources = [
                {
                    element: container,
                    attributes: this.getAttributes(container),
                },
            ];
            initContainer(container, resources);
        }
    }
}
DLightBox.createInstanceObject = (lb) => {
    const instance = {
        open: lb.openContainer.bind(lb),
        close: lb.destroyContainer.bind(lb),
        remove: () => DLightBox.removeInstanceObject(lb),
        bind: lb.addNodeEventListeners.bind(lb),
        listen: lb.listen.bind(lb),
        elements: lb.elements,
        bindings: lb.bindings,
    };
    DLightBox._instances.set(DLightBox._instances.size == 0
        ? 0
        : [...DLightBox._instances.keys()][DLightBox._instances.size - 1] + 1, instance);
    return instance;
};
DLightBox.removeInstanceObject = (lb) => {
    lb.removeNodeEventListeners.call(lb);
    DLightBox._instances.delete(lb.id);
};
export default DLightBox;
