import $ from 'cash-dom';
export default $(function () {
    $('a').each(function (index, el) {
        const href = $(el).attr('href');
        if (!href) {
            return;
        }
        if (window.location.toString().includes(href)) {
            $(el).addClass('active');
        }
        if (href === window.location.hash) {
            $(el).addClass('active');
        }
    });
    window.addEventListener('hashchange', () => {
        $('a').each(function (index, el) {
            var _a;
            if (((_a = $(el).attr('href')) === null || _a === void 0 ? void 0 : _a.indexOf(window.location.hash)) !== -1) {
                $(el).addClass('active');
            }
            else {
                $(el).removeClass('active');
            }
        });
    });
});
