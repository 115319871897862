import $ from 'cash-dom';
export default $(function () {
    scrollTrigger('.reveal');
});
export function scrollTrigger(selector) {
    const els = Array.from(document.querySelectorAll(selector));
    els.forEach((el) => {
        addObserver(el);
    });
}
export function addObserver(el) {
    const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                entry.target.classList.add('revealed');
                observer.unobserve(entry.target);
            }
        });
    }, {
        rootMargin: '-20%',
    });
    observer.observe(el);
}
