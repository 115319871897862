import LightBoxSpinner from '../lightbox-spinner/LightBoxSpinner';
class DragonLightBox {
    constructor(resourceUrl, attributes, config) {
        this.abortDownloadingUnloadedNode = () => {
            this.spinner.element.classList.remove('error');
            if (!this.loaded && this.element.getAttribute('src') != '') {
                this.element.setAttribute('src', '');
                this.element.remove();
                return;
            }
        };
        this.setCommonAttributes = () => {
            if (!this.element || !this.attributes)
                return;
            for (const { name, value } of this.attributes) {
                if (Object.keys(this.config).find((k) => k === name) || !value)
                    continue;
                this.element.setAttribute(name, value);
            }
        };
        this._resourceUrl = resourceUrl;
        this._attributes = attributes;
        this._config = config;
        this._isSelected = false;
        this._element = null;
        this._loaded = false;
        this._spinner = LightBoxSpinner.getSpinner();
        if (!this.config.lazy) {
            this.buildElement();
        }
    }
    get element() {
        return this._element;
    }
    get config() {
        return this._config;
    }
    get attributes() {
        return this._attributes;
    }
    get isSelected() {
        return this._isSelected;
    }
    get loaded() {
        return this._loaded;
    }
    set loaded(loaded) {
        this._loaded = loaded;
    }
    get spinner() {
        return this._spinner;
    }
    set isSelected(isSelected) {
        this._isSelected = isSelected;
    }
    set element(element) {
        this._element = element;
    }
    get resourceUrl() {
        return this._resourceUrl;
    }
    buildElement() { }
    isElementBuilt() {
        if (!this.element)
            return false;
        const attributesValues = Object.values(this.element.attributes).map((attr) => attr.nodeValue);
        if (!attributesValues.includes(this.resourceUrl)) {
            return false;
        }
        return true;
    }
    open() {
        if (!this.loaded || !this.isElementBuilt()) {
            this.buildElement();
            return;
        }
        this.element.style.display = '';
    }
    close() {
        this.abortDownloadingUnloadedNode();
        this.spinner.hideSpinner();
        this.element.style.display = 'none';
        this.isSelected = false;
    }
}
export default DragonLightBox;
