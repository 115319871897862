import ContainerAttributes from "../../constants/containerAttributes";
import LightBoxNode from "./LightBoxNode";
class LightBoxList {
    constructor(lightboxlist, config) {
        this.elements = this.setElementsId(lightboxlist.map(l => l.element));
        this.head = new LightBoxNode(this.elements[0], lightboxlist[0].attributes, config);
        this.head.prev = null;
        let currentNode = this.head;
        for (let i = 1; i < lightboxlist.length; i++) {
            currentNode.next = new LightBoxNode(this.elements[i], lightboxlist[i].attributes, config);
            currentNode.prev = this.lookupByIndex(i - 2);
            currentNode = currentNode.next;
        }
        this.size = lightboxlist.length;
        currentNode.prev = this.lookupByIndex(this.size - 2);
        this.tail = currentNode;
    }
    setElementsId(elementsList) {
        const ids = [...Array(elementsList.length).keys()];
        elementsList.forEach(element => {
            if (element.hasAttribute(ContainerAttributes.ID)) {
                ids.splice(Number(element.getAttribute(ContainerAttributes.ID)), 1);
            }
        });
        elementsList.filter(e => !e.hasAttribute(ContainerAttributes.ID)).forEach((element, i) => element.setAttribute(ContainerAttributes.ID, String(ids[i])));
        return elementsList;
    }
    lookupByIndex(index) {
        let searchNode = this.head;
        for (let i = 0; i < index; i++) {
            searchNode = searchNode.next;
        }
        return searchNode;
    }
    lookupById(id) {
        let searchNode = this.head;
        while (searchNode.id !== id) {
            searchNode = searchNode.next;
        }
        return searchNode;
    }
}
export default LightBoxList;
