import $ from 'cash-dom';
import { animate } from 'popmotion';
const menuOpenClass = 'menu-item--open';
export default $(function () {
    $('.navigation-toggle').on('click', () => {
        $('body').toggleClass('navigation__open');
    });
    const observer = new IntersectionObserver(([e]) => {
        document
            .querySelector('.header-wrapper')
            .classList.toggle('stuck', e.intersectionRatio < 1);
    }, { threshold: [1] });
    observer.observe(document.querySelector('.secondary-navigation'));
    $('.primary-navigation-backdrop').on('click', () => {
        $('body').removeClass('navigation__open');
    });
    const timeOuts = {};
    const menusWithChilden = $('.primary-navigation, .secondary-navigation').find('.menu-item-has-children');
    menusWithChilden.on('mouseenter', (e) => {
        if (window.innerWidth > 1200) {
            const target = e.currentTarget;
            $(target).addClass(menuOpenClass);
            $(target).parent().addClass('has-open-item');
            if (timeOuts[target.className] !== null) {
                clearTimeout(timeOuts[target.className]);
                timeOuts[target.className] = null;
            }
            $(target).on('mouseleave', () => {
                timeOuts[target.className] = setTimeout(() => {
                    if (timeOuts[target.className] !== null) {
                        $(target).removeClass(menuOpenClass);
                        $(target).parent().removeClass('has-open-item');
                    }
                    timeOuts[target.className] = null;
                }, 300);
            });
        }
    });
    if (window.innerWidth < 1200) {
        menusWithChilden.find('.menu').hide();
    }
    menusWithChilden.children('a').on('click', (e) => {
        if (window.innerWidth < 1200) {
            const target = e.currentTarget;
            e.preventDefault();
            const parent = $(target).parent();
            const menu = $(target).siblings('.menu');
            if (menu.height() !== 0) {
                parent.removeClass(menuOpenClass);
                animate({
                    from: menu.innerHeight(),
                    to: 0,
                    onUpdate: (height) => {
                        menu.height(height);
                    },
                    onComplete: () => {
                        menu.hide();
                    },
                });
            }
            else {
                menu.show();
                menu.css('height', 'auto');
                const newHeight = menu.innerHeight();
                parent.addClass(menuOpenClass);
                animate({
                    from: 0,
                    to: newHeight,
                    onUpdate: (height) => {
                        menu.height(height);
                    },
                    onComplete: () => {
                        menu.css('height', 'auto');
                    },
                });
            }
        }
    });
});
